import React from 'react';
import IconCoinStroke from '@assets/icon-coin.svg';
import IconColoredCoin from '@assets/new/icon-colored-coin.svg';
import { SVG_SIZES } from '@utils/constants';
import classNames from 'classnames';
import styles from './css';

type IconProps = {
  color?: 'gray' | 'white' | 'brown' | 'purple' | 'red' | 'black600';
  disabled?: boolean;
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'ml' | 'lg';
  withFill?: boolean;
};

const IconCoin = ({
  color,
  disabled = false,
  size = 'md',
  withFill = true,
}: IconProps = {}) => {
  const style = {
    height: SVG_SIZES[size],
    width: SVG_SIZES[size],
  };

  return (
    <>
      {withFill ? (
        <IconColoredCoin
          className={classNames(
            { [`color-${color}`]: color },
            { disabled },
            'icon-coin with-fill'
          )}
          style={style}
        />
      ) : (
        <IconCoinStroke
          className={classNames({ [`color-${color}`]: color }, 'icon-coin')}
          style={style}
        />
      )}
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default IconCoin;
