import Avatar from '@atoms/Avatar';
import ProfileIconVipFrame from '@atoms/ProfileIconVipFrame';
import { Neighbor } from '@services/hafh/types/generated';
import { ProfileIconSize } from '@utils/types';
import classNames from 'classnames';
import Image from 'next/image';
import styles from './css';

type Props = { neighbor: Neighbor; size: ProfileIconSize };

const badgeSize = {
  lg: 32,
  md: 20,
  sm: 14,
};

const ProfileIcon = ({ neighbor, size }: Props) => {
  const {
    first_stage_badge: firstStageBadge,
    nickname,
    profile_image_url: profileImageUrl,
    vip_rank: vipRank,
  } = neighbor;
  const isVip = vipRank !== 'regular';

  return (
    <div className={classNames('profile-icon', { 'not-vip': !isVip })}>
      <Avatar nickname={nickname} size={size} src={profileImageUrl} />
      {vipRank && <ProfileIconVipFrame size={size} vipRank={vipRank} />}
      {firstStageBadge && (
        <div className={`profile-icon-badge ${size}`}>
          <Image
            alt={`first-stage-badge-${firstStageBadge}`}
            height={badgeSize[size]}
            src={`/images/first-stage-badge/${firstStageBadge}.png`}
            width={badgeSize[size]}
          />
        </div>
      )}
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default ProfileIcon;
