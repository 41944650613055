import { TypoXXSBold } from '@atoms/Typos';
import Link from '@components/Link';
import { useNeighbor } from '@hooks/useNeighbor';
import { useIsJapanUser } from '@utils/hooks';
import routes from '@utils/routes';
import { Translate } from 'next-translate';
import styles from './css';

type Props = {
  t: Translate;
};

const HeaderLink = ({ t }: Props) => {
  const isJapanUser = useIsJapanUser();

  const { isBasicPlan } = useNeighbor();

  const showLink = isJapanUser && isBasicPlan;

  if (!showLink) {
    return;
  }

  return (
    <Link className="header-change-plan-link" href={routes.upgradePlan}>
      <TypoXXSBold color="primary">{t('upgradePlan')}</TypoXXSBold>
      <style jsx={true}>{styles}</style>
    </Link>
  );
};

export default HeaderLink;
