/* eslint-disable */
import React from 'react';

import MediaQuery from '../MediaQuery';

import { BREAKPOINT } from '@utils/constants';

type Props = {
  mobile?: boolean;
  tablet?: boolean;
  desktop?: boolean;
  children: any;
};

const Device = ({
  mobile = false,
  tablet = false,
  desktop = false,
  children,
}: Props) => {
  // NOTE: It does not consider the usage of specifying
  //       only "mobile" and "desktop" at the same time.
  if ((!mobile && !tablet && !desktop) || (mobile && !tablet && desktop)) {
    return <>{children}</>;
  }
  let min, max;
  if (desktop) {
    min = BREAKPOINT.desktopL + 1;
  }
  if (tablet) {
    min = BREAKPOINT.mobile + 1;
  }
  if (mobile) {
    min = 0;
    max = BREAKPOINT.mobile;
  }
  if (tablet) {
    max = BREAKPOINT.desktopL;
  }
  if (desktop) {
    max = undefined;
  }

  const media =
    min && max
      ? `(min-width: ${min}px) and (max-width: ${max}px)`
      : min
      ? `(min-width: ${min}px)`
      : max
      ? `(max-width: ${max}px)`
      : undefined;

  return <MediaQuery media={media}>{children}</MediaQuery>;
};

export default Device;
