import React from 'react';
import IconColoredUser from '@assets/new/icon-colored-user.svg';
import { SVG_SIZES } from '@utils/constants';
import classNames from 'classnames';
import styles from './css';

type IconProps = {
  color?:
    | 'pink'
    | 'blue'
    | 'primary-border'
    | 'brown'
    | 'purple'
    | 'gray'
    | 'white';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  withFill?: boolean;
};

const IconUser = ({ color, size = 'md', withFill = true }: IconProps = {}) => {
  const style = {
    height: SVG_SIZES[size],
    width: SVG_SIZES[size],
  };

  return (
    <div>
      <IconColoredUser
        className={classNames(`color-${color} icon-user`, {
          'no-fill': !withFill,
        })}
        style={style}
      />
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default IconUser;
