import { BREAKPOINT } from '@utils/constants';
import css from 'styled-jsx/css';

const { desktopL } = BREAKPOINT;

const styles = css`
  .header-logo {
    margin-right: 0.25rem;
    width: 64px;

    @media screen and (max-width: ${desktopL}px) {
      width: 64px;
      margin-right: 0;
    }

    :global(.header-logo-link) {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      color: black;
    }
  }
`;

export default styles;
