/* eslint-disable */
import React from 'react';

import MediaQuery from '../MediaQuery';

import { BREAKPOINT } from '@utils/constants';

const Mobile = ({ children }) => (
  <MediaQuery media={`(max-width: ${BREAKPOINT.mobile}px)`}>
    {children}
  </MediaQuery>
);

export default Mobile;
