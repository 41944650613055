import { BREAKPOINT } from '@utils/constants';
import css from 'styled-jsx/css';

const styles = css`
  :global(.header-change-plan-link) {
    :global(.typo) {
      white-space: nowrap;
    }
    @media screen and (min-width: ${BREAKPOINT.desktop}px) {
      margin-right: 12px;
    }
  }
`;

export default styles;
