import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';
import css from 'styled-jsx/css';

const styles = css`
  .icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
    align-items: flex-start;
    width: 100%;
    max-width: 410px;

    @media screen and (max-width: ${BREAKPOINT.desktopL}px) {
      &.not-subscribed {
        gap: 1.5rem;
      }
    }

    .search-button {
      cursor: pointer;
    }

    :global(path) {
      fill: ${COLORS.black600};
    }
    :global(.active path) {
      fill: ${COLORS.primary};
    }

    :global(.icons-inbox) {
      position: relative;
      :global(.unread-badge) {
        position: absolute;
        top: -3px;
        left: 29px;
      }
    }
  }
`;

export default styles;
