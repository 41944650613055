/* eslint-disable */
import React from 'react';

import MediaQuery from '../MediaQuery';

import { BREAKPOINT } from '@utils/constants';

const DesktopL = ({ children }) => (
  <MediaQuery media={`(min-width: ${BREAKPOINT.desktopL + 1}px)`}>
    {children}
  </MediaQuery>
);

export default DesktopL;
