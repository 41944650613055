import React, { FC } from 'react';
import HafhLogo from '@assets/hafh-logo.svg';
import Link from '@components/Link';
import routes from '@utils/routes';
import styles from './css';

type HeaderLogoProps = {
  isLogoClickDisabled?: boolean;
  isLogoH1?: boolean;
  isSignedIn?: boolean;
};

const HeaderLogo: FC<HeaderLogoProps> = ({
  isLogoClickDisabled,
  isLogoH1,
  isSignedIn,
}) => {
  const logo = <HafhLogo />;

  const contents = isLogoClickDisabled ? (
    <div className="header-logo-link">{logo}</div>
  ) : (
    <Link
      className="header-logo-link"
      href={isSignedIn ? routes.browse : routes.root}
    >
      {logo}
    </Link>
  );

  if (isLogoH1) {
    return (
      <h1 className="header-logo">
        {contents}
        <style jsx={true}>{styles}</style>
      </h1>
    );
  }

  return (
    <div className="header-logo">
      {contents}
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default HeaderLogo;
