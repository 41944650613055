import COLORS from '@utils/colors';
import css from 'styled-jsx/css';

const styles = css`
  .profile-icon {
    position: relative;
    border-radius: 50%;

    &.not-vip {
      border: solid 1px ${COLORS.black300};
    }

    &-badge {
      position: absolute;
    }
    .sm {
      right: -3px;
      bottom: -3px;
    }
    .md {
      right: -4px;
      bottom: -2px;
    }
    .lg {
      right: -6px;
      bottom: -3px;
    }
  }
`;

export default styles;
