import React from 'react';
import { TypoXXXS } from '@atoms/Typos';
import classNames from 'classnames';
import styles from './css';

type Props = {
  Icon: any;
  className?: string;
  isActive?: boolean;
  text: string;
};

const IconWithText = ({
  Icon,
  className,
  isActive = false,
  text,
}: Props): JSX.Element => (
  <div className={classNames('icon-with-text', { active: isActive })}>
    <Icon className={classNames(className, 'icon')} />
    <TypoXXXS color={isActive ? 'primary' : 'black600'} text={text} />
    <style jsx={true}>{styles}</style>
  </div>
);

export default IconWithText;
