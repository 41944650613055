/* eslint-disable */
import React from 'react';

import MediaQuery from '../MediaQuery';

import { BREAKPOINT } from '@utils/constants';

const Desktop = ({ children }) => (
  <MediaQuery media={`(min-width: ${BREAKPOINT.mobile + 1}px)`}>
    {children}
  </MediaQuery>
);

export default Desktop;
