import VipFrameGold from '@assets/vip/vip-frame-gold.svg';
import VipFramePlatinum from '@assets/vip/vip-frame-platinum.svg';
import VipFrameSilver from '@assets/vip/vip-frame-silver.svg';
import { ProfileIconSize, VipRank } from '@utils/types';
import styles from './css';

type Props = { size: ProfileIconSize; vipRank: VipRank };

const ProfileIconVipFrame = ({ size, vipRank }: Props) => {
  const isVip = vipRank !== 'regular';

  if (!isVip) {
    return null;
  }

  const renderVipRankFrame = (rank: Exclude<VipRank, 'regular'>) => {
    if (rank === 'platinum') {
      return <VipFramePlatinum />;
    }

    if (rank === 'gold') {
      return <VipFrameGold />;
    }

    if (rank === 'silver') {
      return <VipFrameSilver />;
    }
  };

  return (
    <div className={`profile-avatar-vip-frame ${size}`}>
      {renderVipRankFrame(vipRank)}
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default ProfileIconVipFrame;
