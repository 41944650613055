/* eslint-disable */
import React from 'react';

import styles from './css.js';

const UnreadBadge = ({ count }) => {
  if (count === 0) return null;
  const countToShow = count > 99 ? '99+' : count;
  return (
    <span className="unread-badge">
      <span className="unread-badge-wrapper">{countToShow}</span>

      <style jsx>{styles}</style>
    </span>
  );
};

export default UnreadBadge;
