import React from 'react';
import IconHeartLine from '@assets/icon-heart-line.svg';
import IconInbox from '@assets/icon-inbox.svg';
import IconCalendar from '@assets/new/icon-calendar.svg';
import IconFlight from '@assets/new/icon-flight.svg';
import IconProperty from '@assets/new/icon-property.svg';
import UnreadBadge from '@atoms/UnreadBadge';
import Link from '@components/Link';
import { useAppSelector } from '@hooks/useAppSelector';
import useLocation from '@hooks/useLocation';
import useRegistrationCountry from '@hooks/useRegistrationCountry';
import IconWithText from '@molecules/IconWithText';
import { getNeighborSubscribed } from '@utils/neighbor';
import routes from '@utils/routes';
import { isEmpty } from '@utils/utils';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

type IconNavProps = {
  Icon: React.ComponentType<{ className?: string }>;
  className?: string;
  href: string;
  isActive: boolean;
  text: string;
};

const IconNav = ({ Icon, className, href, isActive, text }: IconNavProps) => (
  <Link href={href}>
    <IconWithText
      Icon={Icon}
      className={className}
      isActive={isActive}
      text={text}
    />
  </Link>
);

const NavIconList: React.FC = () => {
  const { pathname } = useRouter();
  const { t } = useTranslation('nav-icons');

  const { neighbor, unreadCount } = useAppSelector((state) => ({
    neighbor: state.neighbor,
    unreadCount: state.unreadCount,
  }));

  const { isJapan } = useLocation();
  const { isRegistrationCountryJapan } = useRegistrationCountry();

  const showFlightNav = isEmpty(neighbor)
    ? isJapan
    : isRegistrationCountryJapan;

  const showBookingNav = getNeighborSubscribed(neighbor);

  return (
    <div className="icons">
      <IconNav
        Icon={IconProperty}
        href={routes.browse}
        isActive={pathname === routes.properties || pathname === routes.browse}
        text={t('search')}
      />
      {showFlightNav && (
        <IconNav
          Icon={IconFlight}
          href={routes.v2FlightReservationsNew}
          isActive={pathname.includes(routes.v2FlightReservationsNew)}
          text={t('flightSearch')}
        />
      )}
      <IconNav
        Icon={IconHeartLine}
        className="favorite"
        href={routes.favoriteProperties}
        isActive={pathname === routes.favoriteProperties}
        text={t('favorites')}
      />
      {showBookingNav && (
        <IconNav
          Icon={IconCalendar}
          href={routes.reservations}
          isActive={
            pathname === routes.reservations || pathname === routes.reservation
          }
          text={t('bookingHistory')}
        />
      )}
      <div className="icons-inbox test-header-inbox">
        <IconNav
          Icon={IconInbox}
          className="favorite"
          href={routes.inbox}
          isActive={
            pathname === routes.inbox || pathname === routes.inboxDetail
          }
          text={t('inbox')}
        />
        <UnreadBadge count={unreadCount?.total} />
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default NavIconList;
